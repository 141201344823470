import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../components/Footer/FooterThreeDark";
import ImageGridDemo from "../../../components/Image-grid/ImageGridDemo";
import OnePage from "../../../components/Hero/OnePage";
import { ImAndroid } from "react-icons/im";
import {FaApple, FaBriefcaseMedical} from "react-icons/fa";
import {AiOutlineLogin} from "react-icons/ai";
import {IoDocumentText, IoLanguageSharp} from "react-icons/io5";
import KeyFeatures from "../../../components/KeyFeatures";
import {BsChatLeftDots} from "react-icons/bs";
import {MdOutlineReviews} from "react-icons/md";
import ProductHuntFillIcon from "remixicon-react/ProductHuntFillIcon";
import {SiBrandfolder} from "react-icons/si";
import { GiShoppingCart, GiShop } from "react-icons/gi";
import { TiWeatherPartlySunny } from "react-icons/ti";
import { BiMoviePlay } from "react-icons/bi";
import { GiMovementSensor } from "react-icons/gi";
import { MdOutlineTravelExplore } from "react-icons/md";

const imageList = [
    {
        img: "g-store",
        column: "col-lg-12",
        alt: "G-shop mobile UI kit (Android)",
        link: "https://play.google.com/store/apps/details?id=org.gstore",
        new: false,
    },
    {
        img: "g-store",
        column: "col-lg-12",
        alt: "G-shop mobile UI kit (iOS)",
        link: "https://testflight.apple.com/join/OshBaDRr",
        new: false,
    },
];

const skillContent = [
    {
        icon: <ImAndroid />,
        link: "https://play.google.com/store/apps/details?id=org.gstore",
        title: "Android app",
        delayAnimation: "100",
        target: "_blank",
    },
    {
        icon: <FaApple />,
        link: "https://testflight.apple.com/join/OshBaDRr",
        title: "iOS app",
        delayAnimation: "100",
        target: "_blank",
    },
    {
        icon: <IoDocumentText />,
        link: "/gstore-documentation",
        title: "Documentation",
        delayAnimation: "600",
        target: "_blank",
    }
];

function PiHandCoinsLight() {
    return null;
}

function LuListTodo() {
    return null;
}

const features = [
    {
        tag: "Language",
        title: "Multiple Language & RTL",
        subTitle: "Active RTL. You can easily change your preferred language",
        icon: <IoLanguageSharp />,
        color: "#F0EAF6",
        bgColor: "#E2626B",
        delayAnimation: "100",
    },
    {
        tag: "CHAT",
        title: "ONLINE CHAT",
        subTitle: "ONLINE CHAT",
        icon: <BsChatLeftDots />,
        color: "#cce6ff",
        bgColor: "#76b5c5",
        delayAnimation: "200",
    },
    {
        tag: "LOGIN",
        title: "SOCIAL LOGIN",
        subTitle: "SOCIAL LOGIN",
        icon: <AiOutlineLogin />,
        color: "#d6f5d6",
        bgColor: "#248f24",
        delayAnimation: "300",
    },
    {
        tag: "Reviews",
        title: "Reviews",
        subTitle: "You can add review to your products",
        icon: <MdOutlineReviews />,
        color: "#e6e6ff",
        bgColor: "#000099",
        delayAnimation: "400",
    },
    {
        tag: "Product",
        title: "Product",
        subTitle: "Product",
        icon: <ProductHuntFillIcon />,
        color: "#e6e6ff",
        bgColor: "#000099",
        delayAnimation: "500",
    },
    {
        tag: "BRAND",
        title: "BRAND",
        subTitle: "BRAND",
        icon: <SiBrandfolder />,
        color: "#cce6ff",
        bgColor: "#76b5c5",
        delayAnimation: "600",
    },
    {
        tag: "e-Commerce UI",
        title: "e-Commerce",
        subTitle: "e-Commerce UI",
        icon: <GiShoppingCart />,
        color: "#cce6ff",
        bgColor: "#00CED1",
        delayAnimation: "700",
    },
    {
        tag: "Booking UI",
        title: "Booking",
        subTitle: "Booking UI",
        icon: <LuListTodo />,
        color: "#cce6ff",
        bgColor: "#4169E1",
        delayAnimation: "700",
    },
    {
        tag: "Grocery",
        title: "GROCERY",
        subTitle: "Grocery UI",
        icon: <GiShop />,
        color: "#cce6ff",
        bgColor: "#32CD32",
        delayAnimation: "700",
    },
    {
        tag: "Payment",
        title: "Payment",
        subTitle: "Payment",
        icon: <PiHandCoinsLight />,
        color: "#cce6ff",
        bgColor: "#FFD700",
        delayAnimation: "700",
    },
    {
        tag: "Medical",
        title: "Medical",
        subTitle: "Medical",
        icon: <FaBriefcaseMedical />,
        color: "#cce6ff",
        bgColor: "#800080",
        delayAnimation: "700",
    },
    {
        tag: "Weather",
        title: "Weather",
        subTitle: "Weather",
        icon: <TiWeatherPartlySunny />,
        color: "#cce6ff",
        bgColor: "#008080",
        delayAnimation: "700",
    },
    {
        tag: "Movie",
        title: "Movie",
        subTitle: "Movie",
        icon: <BiMoviePlay />,
        color: "#cce6ff",
        bgColor: "#FFA500",
        delayAnimation: "700",
    },
    {
        tag: "Travel",
        title: "Travel",
        subTitle: "Travel",
        icon: <MdOutlineTravelExplore />,
        color: "#cce6ff",
        bgColor: "#708090",
        delayAnimation: "700",
    },    {
        tag: "Animation",
        title: "Animation",
        subTitle: "Animation",
        icon: <GiMovementSensor />,
        color: "#cce6ff",
        bgColor: "#DC143C",
        delayAnimation: "700",
    },
]

export default function MobileUiKitService() {
    return (
        <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
            <Helmet>
                <title>Githubit - Mobile UI Kit</title>
            </Helmet>

            <HeaderDark />
            <div className="ptf-site-wrapper__inner">
                <div className="main">
                    <article className="ptf-page ptf-page--single-work-1">
                        <section>
                            <OnePage
                                skillContent={skillContent}
                                buy_link={
                                    "https://codecanyon.net/user/githubit/portfolio"
                                }
                                descriptions="Gstore is Flutter Framework based Mobile Ui Kit. Which provide Medical, Chat, Travel, Grocery, Payment, Booking, Weather and more Flutter Mobile App"
                                subTitle="Mobile UI Kit"
                                title="G-store"
                                img_url="g-store.png"
                            />
                        </section>

                        <section>
                            <div
                                className="ptf-spacer"
                                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
                            />

                            <KeyFeatures
                                text={
                                    "Gstore is Flutter Framework based Mobile Ui Kit. Which provide Medical, Chat, Travel, Grocery, Payment, Booking, Weather and more Flutter Mobile App"
                                }
                                features={features}
                            />

                            <div className="container-xxl">

                                <div
                                    className="ptf-animated-block"
                                    data-aos="fade"
                                    data-aos-delay="0"
                                >
                                    <h2>All demos</h2>
                                </div>

                                <div
                                    className="ptf-spacer"
                                    style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                                />

                                <div
                                    className="ptf-animated-block"
                                    data-aos="fade"
                                    data-aos-delay="0"
                                >
                                    <ImageGridDemo list={imageList} />
                                </div>

                                <div
                                    className="ptf-spacer"
                                    style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                                />
                                <div
                                    className="ptf-spacer"
                                    style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                                />
                            </div>
                        </section>
                    </article>
                </div>
            </div>

            <footer className="ptf-footer ptf-footer--style-5">
                <div className="container-xxl">
                    <div
                        className="ptf-divider"
                        style={{
                            "--ptf-height": "1px",
                            "--ptf-color": "var(--border-color)",
                        }}
                    />
                    <div className="ptf-footer__top">
                        <FooterThreeDark />
                    </div>

                    <div className="ptf-footer__bottom">
                        <CopyRight />
                    </div>
                </div>
            </footer>
        </div>
    );
}
