import React from "react";

export default function ImageGridDemo({ list = [] }) {
  return (
    <div
      className="ptf-justified-gallery row project-demo"
      style={{
        "--bs-gutter-x": "2rem",
        "--bs-gutter-y": "2rem",
      }}
    >
      {list.map((val, i) => {
        const imgHasExtension = val.img.includes(".");
        return (
          <div className={val.column} key={i}>
            <div className="ptf-gallery__item">
              <div className="ptf-simple-image">
                <a
                  href={val.link}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className="project-item"
                >
                  <img
                    src={`assets/img/project/${
                      imgHasExtension ? val.img : val.img + ".jpg"
                    }`}
                    alt={val.alt}
                    loading="lazy"
                  />
                  <div className="overlay">
                    <h2 className="title">{val.alt}</h2>
                    <span className="demo-btn">View demo</span>
                  </div>
                  {val.new && (
                    <div className="ribbon">
                      <span>New</span>
                    </div>
                  )}
                </a>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
