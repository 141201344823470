import { IoLanguageSharp } from "react-icons/io5";
import {
  MdFavoriteBorder,
  MdOutlineAnalytics,
  MdOutlineCollections,
  MdOutlineComputer,
  MdOutlineReviews,
  MdPayment,
} from "react-icons/md";
import { HiOutlinePuzzle, HiOutlineReceiptRefund } from "react-icons/hi";
import { TbDiscount } from "react-icons/tb";
import { ImMap2 } from "react-icons/im";
import { VscActivateBreakpoints, VscMultipleWindows } from "react-icons/vsc";
import { SiBrandfolder } from "react-icons/si";
import { FiUserCheck } from "react-icons/fi";
import {
  BsChatLeftDots,
  BsClockHistory,
  BsCurrencyExchange,
  BsMoonStars,
  BsWallet2,
} from "react-icons/bs";
import {
  AiOutlineLogin,
  AiOutlineShoppingCart,
  AiOutlineUserAdd,
} from "react-icons/ai";
import {
  RiFileList3Line,
  RiFileListLine,
  RiNotificationBadgeLine,
  RiStore2Line,
} from "react-icons/ri";

const firstLine = "#F0EAF6";
const firstLine2 = "#E2626B";

const secondLine = "#E0E9FA";
const secondLine2 = "#747ED1";

const thirdLine = "#ccffff";
const thirdLine2 = "#008080";

const fourLine = "#cce6ff";
const fourLine2 = "#76b5c5";

const fiveLine = "#d6f5d6";
const fiveLine2 = "#248f24";

const sixthLine = "#e6fff2";
const sixthLine2 = "#45C4A0";

export const features = [
  {
    tag: "Language",
    title: "Multiple Language & RTL",
    subTitle: "Active RTL. You can easily change your preferred language",
    icon: <IoLanguageSharp />,
    color: firstLine,
    bgColor: firstLine2,
    delayAnimation: "100",
  },
  {
    tag: "Multy vendor",
    title: "Multy vendor",
    subTitle: "Multy vendor",
    icon: <AiOutlineUserAdd />,
    color: secondLine,
    bgColor: secondLine2,
    delayAnimation: "100",
  },
  {
    tag: "Multy shop",
    title: "Multy shop",
    subTitle: "Multy shop",
    icon: <VscMultipleWindows />,
    color: thirdLine,
    bgColor: thirdLine2,
    delayAnimation: "100",
  },
  {
    tag: "Single shop",
    title: "Single shop",
    subTitle: "Single shop",
    icon: <RiStore2Line />,
    color: fourLine,
    bgColor: fourLine2,
    delayAnimation: "100",
  },
  {
    tag: "POS Systems",
    title: "Orders",
    subTitle: "Create orders",
    icon: <MdOutlineComputer />,
    color: fiveLine,
    bgColor: fiveLine2,
    delayAnimation: "200",
  },
  {
    tag: "REPORTS",
    title: "REPORTS",
    subTitle: "REPORTS",
    icon: <RiFileList3Line />,
    color: sixthLine,
    bgColor: sixthLine2,
    delayAnimation: "200",
  },
  {
    tag: "Refunds",
    title: "Order refunds",
    subTitle: "Return your created order",
    icon: <HiOutlineReceiptRefund />,
    color: firstLine,
    bgColor: firstLine2,
    delayAnimation: "600",
  },
  {
    tag: "DISCOUNT",
    title: "DISCOUNT AND COUPONS",
    subTitle: "DISCOUNT AND COUPONS",
    icon: <TbDiscount />,
    color: secondLine,
    bgColor: secondLine2,
    delayAnimation: "500",
  },
  {
    tag: "Addon",
    title: "Product addons",
    subTitle: "You can add extras to your products",
    icon: <HiOutlinePuzzle />,
    color: thirdLine,
    bgColor: thirdLine2,
    delayAnimation: "400",
  },
  {
    tag: "Reviews",
    title: "Reviews",
    subTitle: "You can add extras to your products",
    icon: <MdOutlineReviews />,
    color: fourLine,
    bgColor: fourLine2,
    delayAnimation: "300",
  },
  {
    tag: "LookBooks",
    title: "LookBooks",
    subTitle:
      "Unlike product catalogs, this is a presentation of the fashion brand's originality for the new season",
    icon: <MdOutlineCollections />,
    color: fiveLine,
    bgColor: fiveLine2,
    delayAnimation: "200",
  },
  {
    tag: "Analytics",
    title: "Analytics",
    subTitle: "View the values and amounts of your orders and products live",
    icon: <MdOutlineAnalytics />,
    color: sixthLine,
    bgColor: sixthLine2,
    delayAnimation: "100",
  },
  {
    tag: "Map",
    title: "BILLING ADDRESS MAP VIEW",
    subTitle: "BILLING ADDRESS MAP VIEW",
    icon: <ImMap2 />,
    color: firstLine,
    bgColor: firstLine2,
    delayAnimation: "100",
  },
  {
    tag: "FAVORITE",
    title: "FAVORITE PRODUCTS",
    subTitle: "FAVORITE PRODUCTS",
    icon: <MdFavoriteBorder />,
    color: secondLine,
    bgColor: secondLine2,
    delayAnimation: "200",
  },
  {
    tag: "HISTORY",
    title: "ORDER HISTORY",
    subTitle: "ORDER HISTORY",
    icon: <BsClockHistory />,
    color: thirdLine,
    bgColor: thirdLine2,
    delayAnimation: "300",
  },
  {
    tag: "BRAND",
    title: "BRAND",
    subTitle: "BRAND",
    icon: <SiBrandfolder />,
    color: fourLine,
    bgColor: fourLine2,
    delayAnimation: "400",
  },
  {
    tag: "PAYMENT",
    title: "MULTI PAYMENT",
    subTitle: "MULTI PAYMENT",
    icon: <MdPayment />,
    color: fiveLine,
    bgColor: fiveLine2,
    delayAnimation: "500",
  },
  {
    tag: "CURRENCY",
    title: "MULTI CURRENCY",
    subTitle: "MULTI CURRENCY",
    icon: <BsCurrencyExchange />,
    color: sixthLine,
    bgColor: sixthLine2,
    delayAnimation: "600",
  },
  {
    tag: "THEMES",
    title: "DARK AND LIGHT THEMES",
    subTitle: "DARK AND LIGHT THEMES",
    icon: <BsMoonStars />,
    color: firstLine,
    bgColor: firstLine2,
    delayAnimation: "600",
  },
  {
    tag: "ORDERS",
    title: "TRACKING ORDERS",
    subTitle: "TRACKING ORDERS",
    icon: <RiFileListLine />,
    color: secondLine,
    bgColor: secondLine2,
    delayAnimation: "500",
  },
  {
    tag: "PRODUCT",
    title: "EXTRAS PRODUCT",
    subTitle: "EXTRAS PRODUCT",
    icon: <VscActivateBreakpoints />,
    color: thirdLine,
    bgColor: thirdLine2,
    delayAnimation: "400",
  },
  {
    tag: "CHAT",
    title: "ONLINE CHAT",
    subTitle: "ONLINE CHAT",
    icon: <BsChatLeftDots />,
    color: fourLine,
    bgColor: fourLine2,
    delayAnimation: "300",
  },
  {
    tag: "LOGIN",
    title: "SOCIAL LOGIN",
    subTitle: "SOCIAL LOGIN",
    icon: <AiOutlineLogin />,
    color: fiveLine,
    bgColor: fiveLine2,
    delayAnimation: "200",
  },
  {
    tag: "NOTIFICATION",
    title: "PUSH NOTIFICATION",
    subTitle: "PUSH NOTIFICATION",
    icon: <RiNotificationBadgeLine />,
    color: sixthLine,
    bgColor: sixthLine2,
    delayAnimation: "100",
  },
  {
    tag: "WALLET",
    title: "WALLET ",
    subTitle: "WALLET",
    icon: <BsWallet2 />,
    color: firstLine,
    bgColor: firstLine2,
    delayAnimation: "100",
  },
  {
    tag: "SUBSCRIPTION",
    title: "SUBSCRIPTIONS",
    subTitle: "SUBSCRIPTIONS",
    icon: <FiUserCheck />,
    color: secondLine,
    bgColor: secondLine2,
    delayAnimation: "200",
  },
  {
    tag: "BECOME SELLER",
    title: "BECOME SELLER",
    subTitle: "BECOME SELLER",
    icon: <AiOutlineShoppingCart />,
    color: thirdLine,
    bgColor: thirdLine2,
    delayAnimation: "300",
  },
];
