import React from "react";
import { Route } from "react-router-dom";
import FoodyDoc from "../views/Sundaymart_v2-doc";
import FoodyIntroduction from "../views/Sundaymart_v2-doc/Introduction";
import FoodyBasic from "../views/Sundaymart_v2-doc/Basic";
import FoodyServer from "../views/Sundaymart_v2-doc/Server";
import FoodyFront from "../views/Sundaymart_v2-doc/front";
import FoodyFrontQr from "../views/Sundaymart_v2-doc/front-qr";
import FoodyMandatorySetupWebQr from "../views/Sundaymart_v2-doc/Mandatory-setup-web-qr";
import FoodyAdmin from "../views/Sundaymart_v2-doc/admin";
import Recommendations from "../views/Sundaymart_v2-doc/recommendations";
import FoodyLocalFront from "../views/Sundaymart_v2-doc/local-front";
import FoodyMobileApp from "../views/Sundaymart_v2-doc/Mobile-app";
import FoodyFlutterSDK from "../views/Sundaymart_v2-doc/Flutter-SDK";
import FoodyLocalServer from "../views/Sundaymart_v2-doc/Local-server";
import FoodyInstallOnServer from "../views/Sundaymart_v2-doc/Install-on-server";
import FoodyMandatorySetup from "../views/Sundaymart_v2-doc/Mandatory-setup";
import FoodyCustomization from "../views/Sundaymart_v2-doc/Customization";
import FoodyMandatorySetupMobile from "../views/Sundaymart_v2-doc/Mandatory-setup-mobile";
import FoodyMandatorySetupBackend from "../views/Sundaymart_v2-doc/Mandatory-setup-backend";
import PaymentInstallation from "../views/Uzmart-doc/Payment-Installation";
import FoodyImageSettings from "../views/Sundaymart_v2-doc/image-settings";
import FoodyMandatorySetupVendor from "../views/Sundaymart_v2-doc/Moderator-setup-vendor";
import FoodyMandatorySetupCustomer from "../views/Sundaymart_v2-doc/Moderator-setup-customer";
import FoodyMandatorySetupPos from "../views/Sundaymart_v2-doc/Moderator-setup-pos";
import FoodyMandatorySetupDeliveryboy from "../views/Sundaymart_v2-doc/Moderator-setup-deliveryboy";
import FoodyCustomizationMobile from "../views/Sundaymart_v2-doc/Customization-mobile";
import CustomizationMobileDelivery from "../views/Sundaymart_v2-doc/Customization-mobile-delivery";
import CustomizationMobileCustomer from "../views/Sundaymart_v2-doc/Customization-mobile-customer";
import CustomizationMobilePos from "../views/Sundaymart_v2-doc/Customization-mobile-pos";
import AppBuildReleaseCustomer from "../views/Sundaymart_v2-doc/App-build-release-customer";
import AppBuildReleasePos from "../views/Sundaymart_v2-doc/App-build-release-pos";
import AppBuildReleaseVendor from "../views/Sundaymart_v2-doc/App-build-release-vendor";
import AppBuildReleaseDelivery from "../views/Sundaymart_v2-doc/App-build-release-deliveryboy";
import FoodyMandatorySetupWeb from "../views/Sundaymart_v2-doc/Mandatory-setup-web";
import FoodyBuildCodeAndSetupOnServer from "../views/Sundaymart_v2-doc/Build-code-and-setup-on-server";
import FoodyBuildCodeAndSetupOnServerBackend from "../views/Sundaymart_v2-doc/Build-code-and-setup-on-server-backend";
import FoodyUpdateAdminPanel from "../views/Sundaymart_v2-doc/Update-admin-panel";
import FoodyUpdateAppWeb from "../views/Sundaymart_v2-doc/Update-app-web";
import FoodySupportPlan from "../views/Sundaymart_v2-doc/supportPlan";
import FoodyFirebaseSetup from "../views/Sundaymart_v2-doc/firebase-setup";
import TroubleshootingBackend from "../views/Sundaymart_v2-doc/Troubleshooting-backend";
import TroubleshootingAdmin from "../views/Sundaymart_v2-doc/Troubleshooting-admin";
import UpdateFooyman from "../views/Sundaymart_v2-doc/update";
import MandatorySetupKiosk from "../views/Sundaymart_v2-doc/Moderator-setup-kiosk";
import CustomizationMobileKiosk from "../views/Sundaymart_v2-doc/Customization-mobile-kiosk";
import AppBuildReleaseSingleKiosk from "../views/Sundaymart_v2-doc/App-build-release-kiosk";
import FoodyQRApp from "../views/Sundaymart_v2-doc/qr-code";
import FoodyInstallQRcode from "../views/Sundaymart_v2-doc/install-qrcode";

const Sundaymart_v2 = () => {
  return (
    <Route path="/sundaymart_v2-documentation" element={<FoodyDoc />}>
      <Route
        path="/sundaymart_v2-documentation/introduction"
        element={<FoodyIntroduction />}
      />
      <Route
        path="/sundaymart_v2-documentation/basic"
        element={<FoodyBasic />}
      />
      <Route
        path="/sundaymart_v2-documentation/server"
        element={<FoodyServer />}
      />
      <Route
        path="/sundaymart_v2-documentation/front"
        element={<FoodyFront />}
      />
      <Route
        path="/sundaymart_v2-documentation/front-qr"
        element={<FoodyFrontQr />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-web-qr"
        element={<FoodyMandatorySetupWebQr />}
      />
      <Route
        path="/sundaymart_v2-documentation/admin"
        element={<FoodyAdmin />}
      />
      <Route
        path="/sundaymart_v2-documentation/recommendations"
        element={<Recommendations />}
      />
      <Route
        path="/sundaymart_v2-documentation/local-front"
        element={<FoodyLocalFront />}
      />
      <Route
        path="/sundaymart_v2-documentation/mobile-app"
        element={<FoodyMobileApp pageTitle="Customer App" />}
      />
      <Route
        path="/sundaymart_v2-documentation/vendor-app"
        element={<FoodyMobileApp pageTitle="Vendor App" />}
      />
      <Route
        path="/sundaymart_v2-documentation/driver-app"
        element={<FoodyMobileApp pageTitle="Driver App" />}
      />
      <Route
        path="/sundaymart_v2-documentation/pos-app"
        element={<FoodyMobileApp pageTitle="Pos App" />}
      />
      <Route
        path="/sundaymart_v2-documentation/flutter-sdk"
        element={<FoodyFlutterSDK />}
      />
      <Route
        path="/sundaymart_v2-documentation/local-server"
        element={<FoodyLocalServer />}
      />
      <Route
        path="/sundaymart_v2-documentation/install-on-server"
        element={<FoodyInstallOnServer />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup"
        element={<FoodyMandatorySetup />}
      />
      <Route
        path="/sundaymart_v2-documentation/customization"
        element={<FoodyCustomization />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-mobile"
        element={<FoodyMandatorySetupMobile />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-backend"
        element={<FoodyMandatorySetupBackend />}
      />
      <Route
        path="/sundaymart_v2-documentation/payment-installation"
        element={<PaymentInstallation />}
      />
      <Route
        path="/sundaymart_v2-documentation/image-settings"
        element={<FoodyImageSettings />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-vendor"
        element={<FoodyMandatorySetupVendor />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-customer"
        element={<FoodyMandatorySetupCustomer />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-pos"
        element={<FoodyMandatorySetupPos />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-deliveryboy"
        element={<FoodyMandatorySetupDeliveryboy />}
      />
      <Route
        path="/sundaymart_v2-documentation/customization-vendor"
        element={<FoodyCustomizationMobile />}
      />
      <Route
        path="/sundaymart_v2-documentation/customization-deliveryboy"
        element={<CustomizationMobileDelivery />}
      />
      <Route
        path="/sundaymart_v2-documentation/customization-customer"
        element={<CustomizationMobileCustomer />}
      />
      <Route
        path="/sundaymart_v2-documentation/customization-pos"
        element={<CustomizationMobilePos />}
      />
      <Route
        path="/sundaymart_v2-documentation/customer-app-build-release"
        element={<AppBuildReleaseCustomer />}
      />
      <Route
        path="/sundaymart_v2-documentation/pos-app-build-release"
        element={<AppBuildReleasePos />}
      />
      <Route
        path="/sundaymart_v2-documentation/vendor-app-build-release"
        element={<AppBuildReleaseVendor />}
      />
      <Route
        path="/sundaymart_v2-documentation/deliveryboy-app-build-release"
        element={<AppBuildReleaseDelivery />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-web"
        element={<FoodyMandatorySetupWeb />}
      />
      <Route
        path="/sundaymart_v2-documentation/build-code-and-setup-on-server"
        element={<FoodyBuildCodeAndSetupOnServer />}
      />
      <Route
        path="/sundaymart_v2-documentation/build-code-and-setup-on-server-backend"
        element={<FoodyBuildCodeAndSetupOnServerBackend />}
      />
      <Route
        path="/sundaymart_v2-documentation/update-admin-panel"
        element={<FoodyUpdateAdminPanel />}
      />
      <Route
        path="/sundaymart_v2-documentation/update-app-web"
        element={<FoodyUpdateAppWeb />}
      />
      <Route
        path="/sundaymart_v2-documentation/support-plan"
        element={<FoodySupportPlan />}
      />
      <Route
        path="/sundaymart_v2-documentation/google-services"
        element={<FoodyFirebaseSetup />}
      />

      <Route
        path="/sundaymart_v2-documentation/troubleshooting-backend"
        element={<TroubleshootingBackend />}
      />
      <Route
        path="/sundaymart_v2-documentation/troubleshooting-admin"
        element={<TroubleshootingAdmin />}
      />
      <Route
        path="/sundaymart_v2-documentation/update"
        element={<UpdateFooyman />}
      />
      <Route
        path="/sundaymart_v2-documentation/kiosk-app"
        element={<FoodyMobileApp pageTitle="Kiosk App" />}
      />
      <Route
        path="/sundaymart_v2-documentation/mandatory-setup-kiosk"
        element={<MandatorySetupKiosk />}
      />
      <Route
        path="/sundaymart_v2-documentation/customization-kiosk"
        element={<CustomizationMobileKiosk />}
      />
      <Route
        path="/sundaymart_v2-documentation/kiosk-app-build-release"
        element={<AppBuildReleaseSingleKiosk />}
      />
      <Route
        path="/sundaymart_v2-documentation/qr-app"
        element={<FoodyQRApp />}
      />
      <Route
        path="/sundaymart_v2-documentation/install-qrcode"
        element={<FoodyInstallQRcode />}
      />
    </Route>
  );
};

export default Sundaymart_v2;
