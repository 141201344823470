import React from "react";
import {
  IoLanguageSharp,
  IoPieChartSharp,
  IoStorefrontOutline,
} from "react-icons/io5";
import {
  MdFavoriteBorder,
  MdLocationOn,
  MdOutlineAnalytics,
  MdOutlineCollections,
  MdOutlineComputer,
  MdOutlineReviews,
  MdPayment,
  MdPointOfSale,
  MdSchedule,
  MdAddLocationAlt,
  MdLocalMall,
  MdMultipleStop,
  MdNotificationsActive, MdNewspaper
} from "react-icons/md";
import {
  FaCashRegister,
  FaChartBar,
  FaCoins,
  FaComments,
  FaEnvelopeOpenText,
  FaGifts,
  FaHandHoldingUsd,
  FaLink,
  FaMoneyBillWave,
  FaRegMap,
  FaShippingFast,
  FaSortAmountDown,
  FaSortAmountUp,
  FaStar,
  FaSubscript, FaUtensils,
  FaWallet,
  FaWarehouse
} from "react-icons/fa";
import {
  HiGlobeAlt,
  HiOutlinePuzzle,
  HiOutlineReceiptRefund,
  HiShoppingBag,
} from "react-icons/hi";
import FlagFillIcon from "remixicon-react/FlagFillIcon";
import {
  GrCurrency,
  GrDeliver,
  GrList,
  GrLocationPin,
  GrWorkshop,
} from "react-icons/gr";
import {
  BsBag,
  BsChatLeftDots,
  BsClockHistory,
  BsCurrencyExchange,
  BsMoonStars,
  BsWallet2,
} from "react-icons/bs";
import {
  AiOutlineLogin,
  AiOutlineShoppingCart,
  AiOutlineTransaction,
} from "react-icons/ai";
import {
  RiCoinsLine,
  RiFileList3Line,
  RiNotificationBadgeLine,
} from "react-icons/ri";
import CarFillIcon from "remixicon-react/CarFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import { BiGitCompare } from "react-icons/bi";
import { FcMultipleDevices } from "react-icons/fc";
import DashboardFillIcon from "remixicon-react/DashboardFillIcon";
import DashboardLineIcon from "remixicon-react/DashboardLineIcon";
import Dashboard3LineIcon from "remixicon-react/Dashboard3LineIcon";
import { ImParagraphCenter } from "react-icons/im";
import { CgWebsite } from "react-icons/cg";
// import { TbDiscount, TbDiscount, TbLogs } from "react-icons/tb";
import { TbTransactionDollar } from "react-icons/tb";
import Coupon2FillIcon from "remixicon-react/Coupon2FillIcon";
import StoreFillIcon from "remixicon-react/StoreFillIcon";
import { GiPaper } from "react-icons/gi";
import ProductHuntFillIcon from "remixicon-react/ProductHuntFillIcon";
import { SiBrandfolder } from "react-icons/si";
import { PiMapPinArea, PiTrademark } from "react-icons/pi";
import { LuCirclePlus, LuLayers3 } from "react-icons/lu";
import { GoShieldCheck } from "react-icons/go";
import { IoMdLogIn } from "react-icons/io";

export const sundaymart_v2DocMenu = [
  {
    name: "Introduction",
    dropDownItems: [
      {
        name: "Introduction",
        routerPath: "/sundaymart_v2-documentation/introduction",
      },
      {
        name: "Server requirements",
        routerPath: "/sundaymart_v2-documentation/recommendations",
      },
    ],
  },
  {
    name: "Backend Api",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart_v2-documentation/server",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart_v2-documentation/mandatory-setup-backend",
      },
      {
        name: "Payment installation",
        routerPath: "/sundaymart_v2-documentation/payment-installation",
      },
      {
        name: "Image settings",
        routerPath: "/sundaymart_v2-documentation/image-settings",
      },
      {
        name: "Troubleshooting",
        routerPath: "/sundaymart_v2-documentation/troubleshooting-backend",
      },
    ],
  },
  {
    name: "Admin Panel",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart_v2-documentation/admin",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart_v2-documentation/install-on-server",
      },
      {
        name: "Troubleshooting",
        routerPath: "/sundaymart_v2-documentation/troubleshooting-admin",
      },
    ],
  },
  {
    name: "Customer Website",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart_v2-documentation/front",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart_v2-documentation/mandatory-setup-web",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Customer App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart_v2-documentation/mobile-app",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart_v2-documentation/mandatory-setup-customer",
      },
      {
        name: "Customization",
        routerPath: "/sundaymart_v2-documentation/customization-customer",
      },
      {
        name: "App build & release",
        routerPath: "/sundaymart_v2-documentation/customer-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Seller App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart_v2-documentation/vendor-app",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart_v2-documentation/mandatory-setup-vendor",
      },
      {
        name: "Customization",
        routerPath: "/sundaymart_v2-documentation/customization-vendor",
      },
      {
        name: "App build & release",
        routerPath: "/sundaymart_v2-documentation/vendor-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Driver App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/sundaymart_v2-documentation/driver-app",
      },
      {
        name: "Installation",
        routerPath: "/sundaymart_v2-documentation/mandatory-setup-deliveryboy",
      },
      {
        name: "Customization",
        routerPath: "/sundaymart_v2-documentation/customization-deliveryboy",
      },
      {
        name: "App build & release",
        routerPath:
          "/sundaymart_v2-documentation/deliveryboy-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Google services setup",
    routerPath: "/sundaymart_v2-documentation/google-services",
  },
  {
    name: "Update",
    routerPath: "/sundaymart_v2-documentation/update",
  },
];

export const payments = [
  {
    icon: (
      <img
        src={"./assets/img/root/paypal.png"}
        alt="img"
        width="80"
        height="80"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/stripe.png"}
        alt="img"
        width="80"
        height="80"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/razorpay.png"}
        alt="img"
        width="80"
        height="80"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/paystack.jpeg"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/pay-tabs.jpg"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/flw.png"}
        alt="img"
        width="110"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/mercado-pago.jpg"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/moyasar.png"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/mollie.png"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/maksekeskus.png"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/zain-cash.png"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
  {
    icon: (
      <img
        src={"./assets/img/root/iyzico.png"}
        alt="img"
        width="120"
        height="120"
      />
    ),
  },
];

export const features = [
  {
    tag: "Language",
    title: "Multiple Language & RTL",
    // subTitle: "Active RTL. You can easily change your preferred language",
    icon: <IoLanguageSharp />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Language",
    title: "Multiple Currencies",
    icon: <BsCurrencyExchange />,
    color: "#F0EAF6",
    bgColor: "#0d11af",
    delayAnimation: "100",
  },
  {
    tag: "Currency",
    title: "Built-in POS System",
    icon: <MdPointOfSale />,
    color: "#F0EAF6",
    bgColor: "#11933f",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Multiple delivery options",
    icon: <FaShippingFast />,
    color: "#F0EAF6",
    bgColor: "#7124ff",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Self Pickup Option",
    icon: <HiShoppingBag />,
    color: "#F0EAF6",
    bgColor: "#004eac",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Scheduled Delivery",
    icon: <MdSchedule />,
    color: "#F0EAF6",
    bgColor: "#7124ff",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Saving Multiple Delivery Locations",
    icon: <MdAddLocationAlt />,
    color: "#F0EAF6",
    bgColor: "#dcc137",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Draw Coverage Area on Map",
    icon: <PiMapPinArea />,
    color: "#F0EAF6",
    bgColor: "#0f5caf",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Become a seller",
    icon: <IoStorefrontOutline />,
    color: "#F0EAF6",
    bgColor: "#3f9a2b",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Coupons & Discounts",
    icon: <FaGifts />,
    color: "#F0EAF6",
    bgColor: "#881d24",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Shop & Product Bonus",
    icon: <MdLocalMall />,
    color: "#F0EAF6",
    bgColor: "#0a1b8c",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Cashback",
    icon: <FaMoneyBillWave />,
    color: "#F0EAF6",
    bgColor: "#a11948",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Wallet",
    icon: <FaWallet />,
    color: "#F0EAF6",
    bgColor: "#148585",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Partial Payment via Wallet",
    icon: <RiCoinsLine />,
    color: "#F0EAF6",
    bgColor: "#7c1c22",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Wallet top-up",
    icon: <LuCirclePlus />,
    color: "#F0EAF6",
    bgColor: "#7c1a83",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Multiple payment gateways",
    icon: <MdMultipleStop />,
    color: "#F0EAF6",
    bgColor: "#399124",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Cash on delivery",
    icon: <FaHandHoldingUsd />,
    color: "#F0EAF6",
    bgColor: "#2b9d94",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Secure payments",
    icon: <GoShieldCheck />,
    color: "#F0EAF6",
    bgColor: "#2b629f",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Category Priority Setup",
    icon: <FaSortAmountUp />,
    color: "#F0EAF6",
    bgColor: "#06438d",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Products properties",
    icon: <GrList />,
    color: "#F0EAF6",
    bgColor: "#251372",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Social & Manual Login",
    icon: <IoMdLogIn />,
    color: "#F0EAF6",
    bgColor: "#4403b0",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Email & Phone Verification",
    icon: <FaEnvelopeOpenText />,
    color: "#F0EAF6",
    bgColor: "#084a98",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Push Notifications",
    icon: <MdNotificationsActive />,
    color: "#F0EAF6",
    bgColor: "#0d4f9d",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Refunds",
    icon: <AiOutlineTransaction />,
    color: "#F0EAF6",
    bgColor: "#1b7008",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Ratings & Reviews",
    icon: <FaStar />,
    color: "#F0EAF6",
    bgColor: "#7124ff",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Referral",
    icon: <FaLink />,
    color: "#F0EAF6",
    bgColor: "#1f89ff",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Online chat",
    icon: <FaComments />,
    color: "#F0EAF6",
    bgColor: "#ffd91e",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Reports & Analytics",
    icon: <FaChartBar />,
    color: "#F0EAF6",
    bgColor: "#3b069b",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Blogs",
    icon: <MdNewspaper />,
    color: "#F0EAF6",
    bgColor: "#21830c",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Recipes",
    icon: <FaUtensils />,
    color: "#F0EAF6",
    bgColor: "#004eac",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Brands",
    icon: <PiTrademark />,
    color: "#F0EAF6",
    bgColor: "#0951a9",
    delayAnimation: "100",
  },
  {
    tag: "Feature",
    title: "Multiple Units",
    icon: <LuLayers3 />,
    color: "#F0EAF6",
    bgColor: "#944708",
    delayAnimation: "100",
  },
];
