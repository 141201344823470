import { FaRegMap, FaSubscript, FaWarehouse } from "react-icons/fa";
import { IoLanguageSharp, IoPieChartSharp } from "react-icons/io5";
import {
  MdFavoriteBorder,
  MdOutlineAnalytics,
  MdOutlineCollections,
  MdOutlineComputer,
  MdOutlineReviews,
  MdPayment,
} from "react-icons/md";
import { HiOutlinePuzzle, HiOutlineReceiptRefund } from "react-icons/hi";
import { BiGitCompare } from "react-icons/bi";
import { CgWebsite } from "react-icons/cg";
import { TbDiscount } from "react-icons/tb";
import { FcMultipleDevices } from "react-icons/fc";
import { GiPaper } from "react-icons/gi";
import { ImParagraphCenter } from "react-icons/im";
import {
  GrCurrency,
  GrDeliver,
  GrLocationPin,
  GrWorkshop,
} from "react-icons/gr";
import Coupon2FillIcon from "remixicon-react/Coupon2FillIcon";
import DashboardFillIcon from "remixicon-react/DashboardFillIcon";
import Dashboard3LineIcon from "remixicon-react/Dashboard3LineIcon";
import DashboardLineIcon from "remixicon-react/DashboardLineIcon";
import StoreFillIcon from "remixicon-react/StoreFillIcon";
import CloseCircleFillIcon from "remixicon-react/CloseCircleFillIcon";
import ProductHuntFillIcon from "remixicon-react/ProductHuntFillIcon";
import FlagFillIcon from "remixicon-react/FlagFillIcon";
import {
  BsChatLeftDots,
  BsClockHistory,
  BsMoonStars,
  BsWallet2,
  BsBag,
} from "react-icons/bs";
import { RiFileList3Line, RiNotificationBadgeLine } from "react-icons/ri";
import { SiBrandfolder } from "react-icons/si";
import { AiOutlineLogin, AiOutlineShoppingCart } from "react-icons/ai";
import CarFillIcon from "remixicon-react/CarFillIcon";
import React from "react";

export const features = [
  {
    tag: "Language",
    title: "Multiple Language & RTL",
    subTitle: "Active RTL. You can easily change your preferred language",
    icon: <IoLanguageSharp />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "POS Systems",
    title: "Orders",
    subTitle: "Create orders",
    icon: <MdOutlineComputer />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Delivery Zone",
    title: "Delivery zone",
    subTitle: "Create a zone by clicking on the map",
    icon: <FaRegMap />,
    color: "#fff7e6",
    bgColor: "#FAB758",
    delayAnimation: "300",
  },
  {
    tag: "Refunds",
    title: "Order refunds",
    subTitle: "Return your created order",
    icon: <HiOutlineReceiptRefund />,
    color: "#E0E9FA",
    bgColor: "#747ED1",
    delayAnimation: "400",
  },
  {
    tag: "Addon",
    title: "Product addons",
    subTitle: "You can add extras to your products",
    icon: <HiOutlinePuzzle />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Reviews",
    title: "Reviews",
    subTitle: "You can add extras to your products",
    icon: <MdOutlineReviews />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
];

export const uzmart = [
  {
    tag: "Multi Regions",
    title: "Multi Regions",
    subTitle: "Multi Regions, Countries,Cities",
    icon: <FlagFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Language",
    title: "Multiple Language & RTL",
    subTitle: "Active RTL. You can easily change your preferred language",
    icon: <IoLanguageSharp />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Currency",
    title: "Multiple Currency",
    subTitle: "You can easily change your preferred currency",
    icon: <GrCurrency />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "THEMES",
    title: "DARK AND LIGHT THEMES",
    subTitle: "DARK AND LIGHT THEMES",
    icon: <BsMoonStars />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "600",
  },
  {
    tag: "Multi shop",
    title: "Multi shop",
    subTitle: "can be purchased from several shops",
    icon: <BsBag />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },

  {
    tag: "PAYMENT",
    title: "MULTI PAYMENT",
    subTitle: "MULTI PAYMENT",
    icon: <MdPayment />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "500",
  },
  {
    tag: "CHAT",
    title: "ONLINE CHAT",
    subTitle: "ONLINE CHAT",
    icon: <BsChatLeftDots />,
    color: "#cce6ff",
    bgColor: "#76b5c5",
    delayAnimation: "300",
  },
  {
    tag: "LOGIN",
    title: "SOCIAL LOGIN",
    subTitle: "SOCIAL LOGIN",
    icon: <AiOutlineLogin />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "NOTIFICATION",
    title: "PUSH NOTIFICATION",
    subTitle: "PUSH NOTIFICATION",
    icon: <RiNotificationBadgeLine />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "100",
  },
  {
    tag: "WALLET",
    title: "WALLET ",
    subTitle: "WALLET",
    icon: <BsWallet2 />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "BECOME SELLER",
    title: "BECOME SELLER",
    subTitle: "BECOME SELLER",
    icon: <AiOutlineShoppingCart />,
    color: "#ccffff",
    bgColor: "#008080",
    delayAnimation: "300",
  },
  {
    tag: "BECOME DRIVER",
    title: "BECOME DRIVER",
    subTitle: "BECOME DRIVER",
    icon: <CarFillIcon />,
    color: "#ccffff",
    bgColor: "#008080",
    delayAnimation: "300",
  },
  {
    tag: "Delivery price",
    title: "Delivery price",
    subTitle: "Delivery price by region",
    icon: <GrDeliver />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Delivery point",
    title: "Delivery point",
    subTitle: "Delivery point by location",
    icon: <GrLocationPin />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Delivery point working days",
    title: "Delivery point working days",
    subTitle: "You can setting delivery point working days",
    icon: <GrWorkshop />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Delivery point closed days",
    title: "Delivery point closed days",
    subTitle: "You can setting delivery point closed days",
    icon: <CloseCircleFillIcon />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Warehouse",
    title: "Warehouse",
    subTitle: "Warehouse location",
    icon: <FaWarehouse />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Warehouse working days",
    title: "Warehouse working days",
    subTitle: "You can setting warehouse working days",
    icon: <GrWorkshop />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Warehouse closed days",
    title: "Warehouse closed days",
    subTitle: "You can setting warehouse closed days",
    icon: <CloseCircleFillIcon />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Shop working days",
    title: "Shop working days",
    subTitle: "You can setting shop working days",
    icon: <GrWorkshop />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Shop closed days",
    title: "Shop closed days",
    subTitle: "You can setting shop closed days",
    icon: <CloseCircleFillIcon />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Compare",
    title: "Compare",
    subTitle: "Compare",
    icon: <BiGitCompare />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "customer",
    title: "Customer app",
    subTitle: "Customer app",
    icon: <FcMultipleDevices />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Admin panel",
    title: "Admin panel",
    subTitle: "Admin panel",
    icon: <DashboardFillIcon />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Seller app",
    title: "Seller app",
    subTitle: "Seller app",
    icon: <DashboardLineIcon />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Driver app",
    title: "Driver app",
    subTitle: "Driver app",
    icon: <Dashboard3LineIcon />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "POS Systems",
    title: "Orders",
    subTitle: "Create orders",
    icon: <BsBag />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Parcel order",
    title: "Parcel order",
    subTitle: "Create parcel order",
    icon: <ImParagraphCenter />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Countries",
    title: "Countries",
    subTitle: "Create a regions countries cities by running seeder",
    icon: <FaRegMap />,
    color: "#fff7e6",
    bgColor: "#FAB758",
    delayAnimation: "300",
  },
  {
    tag: "Refunds",
    title: "Order refunds",
    subTitle: "Return your created order",
    icon: <HiOutlineReceiptRefund />,
    color: "#E0E9FA",
    bgColor: "#747ED1",
    delayAnimation: "400",
  },
  {
    tag: "Addon",
    title: "Product addons",
    subTitle: "You can add extras to your products",
    icon: <HiOutlinePuzzle />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Property",
    title: "Property",
    subTitle: "You can add properties to your products",
    icon: <HiOutlinePuzzle />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Digital",
    title: "Digital products",
    subTitle: "You can add digital products",
    icon: <CgWebsite />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Bonus",
    title: "Bonus products",
    subTitle: "You can add bonus products",
    icon: <TbDiscount />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Discount",
    title: "Discount",
    subTitle: "You can add discount on products",
    icon: <TbDiscount />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Coupon",
    title: "coupon",
    subTitle: "You can add a coupon for products or for the delivery amount",
    icon: <Coupon2FillIcon />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Reviews",
    title: "Reviews",
    subTitle: "You can add review to your products",
    icon: <MdOutlineReviews />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Units",
    title: "Units",
    subTitle: "You can add unit to products",
    icon: <IoPieChartSharp />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Subscriptions",
    title: "Subscriptions",
    subTitle: "You can use subscriptions mode",
    icon: <FaSubscript />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Stories",
    title: "Stories",
    subTitle: "Add stories",
    icon: <StoreFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Ads",
    title: "Ads",
    subTitle: "Add ads for shops",
    icon: <GiPaper />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Product History",
    title: "Product History",
    subTitle: "Product History",
    icon: <ProductHuntFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "FAVORITE PRODUCTS",
    title: "FAVORITE PRODUCTS",
    subTitle: "FAVORITE PRODUCTS",
    icon: <MdFavoriteBorder />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "REPORTS",
    title: "REPORTS",
    subTitle: "REPORTS",
    icon: <RiFileList3Line />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "LookBooks",
    title: "LookBooks",
    subTitle:
      "Unlike product catalogs, this is a presentation of the fashion brand's originality for the new season",
    icon: <MdOutlineCollections />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "Analytics",
    title: "Analytics",
    subTitle: "View the values and amounts of your orders and products live",
    icon: <MdOutlineAnalytics />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "100",
  },
  {
    tag: "HISTORY",
    title: "ORDER HISTORY",
    subTitle: "ORDER HISTORY",
    icon: <BsClockHistory />,
    color: "#ccffff",
    bgColor: "#008080",
    delayAnimation: "300",
  },
  {
    tag: "BRAND",
    title: "BRAND",
    subTitle: "BRAND",
    icon: <SiBrandfolder />,
    color: "#cce6ff",
    bgColor: "#76b5c5",
    delayAnimation: "400",
  },
];

export const singleUzmart = [
  {
    tag: "Multi Branches",
    title: "Multi Branches",
    subTitle: "Multi Branches",
    icon: <StoreFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Multi Regions",
    title: "Multi Regions",
    subTitle: "Multi Regions, Countries,Cities",
    icon: <FlagFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Language",
    title: "Multiple Language & RTL",
    subTitle: "Active RTL. You can easily change your preferred language",
    icon: <IoLanguageSharp />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Currency",
    title: "Multiple Currency",
    subTitle: "You can easily change your preferred currency",
    icon: <GrCurrency />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "THEMES",
    title: "DARK AND LIGHT THEMES",
    subTitle: "DARK AND LIGHT THEMES",
    icon: <BsMoonStars />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "600",
  },
  {
    tag: "Multi shop",
    title: "Multi shop",
    subTitle: "can be purchased from several shops",
    icon: <BsBag />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },

  {
    tag: "PAYMENT",
    title: "MULTI PAYMENT",
    subTitle: "MULTI PAYMENT",
    icon: <MdPayment />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "500",
  },
  {
    tag: "CHAT",
    title: "ONLINE CHAT",
    subTitle: "ONLINE CHAT",
    icon: <BsChatLeftDots />,
    color: "#cce6ff",
    bgColor: "#76b5c5",
    delayAnimation: "300",
  },
  {
    tag: "LOGIN",
    title: "SOCIAL LOGIN",
    subTitle: "SOCIAL LOGIN",
    icon: <AiOutlineLogin />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "NOTIFICATION",
    title: "PUSH NOTIFICATION",
    subTitle: "PUSH NOTIFICATION",
    icon: <RiNotificationBadgeLine />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "100",
  },
  {
    tag: "WALLET",
    title: "WALLET ",
    subTitle: "WALLET",
    icon: <BsWallet2 />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "BECOME SELLER",
    title: "BECOME SELLER",
    subTitle: "BECOME SELLER",
    icon: <AiOutlineShoppingCart />,
    color: "#ccffff",
    bgColor: "#008080",
    delayAnimation: "300",
  },
  {
    tag: "BECOME DRIVER",
    title: "BECOME DRIVER",
    subTitle: "BECOME DRIVER",
    icon: <CarFillIcon />,
    color: "#ccffff",
    bgColor: "#008080",
    delayAnimation: "300",
  },
  {
    tag: "Delivery price",
    title: "Delivery price",
    subTitle: "Delivery price by region",
    icon: <GrDeliver />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Delivery point",
    title: "Delivery point",
    subTitle: "Delivery point by location",
    icon: <GrLocationPin />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Delivery point working days",
    title: "Delivery point working days",
    subTitle: "You can setting delivery point working days",
    icon: <GrWorkshop />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Delivery point closed days",
    title: "Delivery point closed days",
    subTitle: "You can setting delivery point closed days",
    icon: <CloseCircleFillIcon />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Warehouse",
    title: "Warehouse",
    subTitle: "Warehouse location",
    icon: <FaWarehouse />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Warehouse working days",
    title: "Warehouse working days",
    subTitle: "You can setting warehouse working days",
    icon: <GrWorkshop />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Warehouse closed days",
    title: "Warehouse closed days",
    subTitle: "You can setting warehouse closed days",
    icon: <CloseCircleFillIcon />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Shop working days",
    title: "Shop working days",
    subTitle: "You can setting shop working days",
    icon: <GrWorkshop />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Shop closed days",
    title: "Shop closed days",
    subTitle: "You can setting shop closed days",
    icon: <CloseCircleFillIcon />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Compare",
    title: "Compare",
    subTitle: "Compare",
    icon: <BiGitCompare />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "customer",
    title: "Customer app",
    subTitle: "Customer app",
    icon: <FcMultipleDevices />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Admin panel",
    title: "Admin panel",
    subTitle: "Admin panel",
    icon: <DashboardFillIcon />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Seller app",
    title: "Seller app",
    subTitle: "Seller app",
    icon: <DashboardLineIcon />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Driver app",
    title: "Driver app",
    subTitle: "Driver app",
    icon: <Dashboard3LineIcon />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "POS Systems",
    title: "Orders",
    subTitle: "Create orders",
    icon: <BsBag />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Parcel order",
    title: "Parcel order",
    subTitle: "Create parcel order",
    icon: <ImParagraphCenter />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Countries",
    title: "Countries",
    subTitle: "Create a regions countries cities by running seeder",
    icon: <FaRegMap />,
    color: "#fff7e6",
    bgColor: "#FAB758",
    delayAnimation: "300",
  },
  {
    tag: "Refunds",
    title: "Order refunds",
    subTitle: "Return your created order",
    icon: <HiOutlineReceiptRefund />,
    color: "#E0E9FA",
    bgColor: "#747ED1",
    delayAnimation: "400",
  },
  {
    tag: "Addon",
    title: "Product addons",
    subTitle: "You can add extras to your products",
    icon: <HiOutlinePuzzle />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Property",
    title: "Property",
    subTitle: "You can add properties to your products",
    icon: <HiOutlinePuzzle />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Digital",
    title: "Digital products",
    subTitle: "You can add digital products",
    icon: <CgWebsite />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Bonus",
    title: "Bonus products",
    subTitle: "You can add bonus products",
    icon: <TbDiscount />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Discount",
    title: "Discount",
    subTitle: "You can add discount on products",
    icon: <TbDiscount />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Coupon",
    title: "coupon",
    subTitle: "You can add a coupon for products or for the delivery amount",
    icon: <Coupon2FillIcon />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Reviews",
    title: "Reviews",
    subTitle: "You can add review to your products",
    icon: <MdOutlineReviews />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Units",
    title: "Units",
    subTitle: "You can add unit to products",
    icon: <IoPieChartSharp />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Subscriptions",
    title: "Subscriptions",
    subTitle: "You can use subscriptions mode",
    icon: <FaSubscript />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Stories",
    title: "Stories",
    subTitle: "Add stories",
    icon: <StoreFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Ads",
    title: "Ads",
    subTitle: "Add ads for shops",
    icon: <GiPaper />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Product History",
    title: "Product History",
    subTitle: "Product History",
    icon: <ProductHuntFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "FAVORITE PRODUCTS",
    title: "FAVORITE PRODUCTS",
    subTitle: "FAVORITE PRODUCTS",
    icon: <MdFavoriteBorder />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "REPORTS",
    title: "REPORTS",
    subTitle: "REPORTS",
    icon: <RiFileList3Line />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "LookBooks",
    title: "LookBooks",
    subTitle:
      "Unlike product catalogs, this is a presentation of the fashion brand's originality for the new season",
    icon: <MdOutlineCollections />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "Analytics",
    title: "Analytics",
    subTitle: "View the values and amounts of your orders and products live",
    icon: <MdOutlineAnalytics />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "100",
  },
  {
    tag: "HISTORY",
    title: "ORDER HISTORY",
    subTitle: "ORDER HISTORY",
    icon: <BsClockHistory />,
    color: "#ccffff",
    bgColor: "#008080",
    delayAnimation: "300",
  },
  {
    tag: "BRAND",
    title: "BRAND",
    subTitle: "BRAND",
    icon: <SiBrandfolder />,
    color: "#cce6ff",
    bgColor: "#76b5c5",
    delayAnimation: "400",
  },
];
