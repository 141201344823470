import React from "react";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { Link } from "react-router-dom";
import NavigationBtns from "./Navigation-btns";

const FirebaseSetup = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h2 className="title">Firebase</h2>
      <p className="inner-text">
        We use Firebase Authentication, Cloud Messaging and Firestore in
        Foodyman. So, you have to setup Firebase in order to use Foodyman
        properly.
      </p>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/OLwNp_e5bxM"
          title="Firebase configuration"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/jCgZZiz1480"
          title="How to run connect firebase to project"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>

      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5HzrGiY9cFo"
          title="Firebase auth configuration"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/cchc8T1QnCE?si=RvYlapwJ2S-oMd0h"
          title="Cloud messaging"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="">
        <p style={{ margin: "10px" }}>
          Add downloaded file to backend_folder/storage/app and run the command
          php artisan storage:link if you haven’t run it before
        </p>
        <img
          src={`../assets/img/google-service-json.jpg`}
          alt="icon"
          style={{ width: "100%" }}
        />
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/29ARDVIXvXk"
          title="Firestore"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <h2 className="title">Google maps</h2>
      <p className="inner-text">
        In this project we integrated Google Maps. You have to setup Google Maps
      </p>
      <p>
        Open &nbsp;
        <a
          href="https://console.cloud.google.com/"
          target="_blank"
          rel="noreferrer"
        >
          <u>Google recaptcha admin</u>
        </a>
      </p>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/map_setup1.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/map_setup1.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/map_setup2.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/map_setup2.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/map_setup3.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/map_setup3.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/map_setup4.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/map_setup4.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/map_setup5.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/map_setup5.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/map_setup6.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/map_setup6.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <h2 className="title">Google recaptcha</h2>
      <p className="inner-text">
        Google recaptcha is used in admin panel's login page.
      </p>
      <p>
        Open &nbsp;
        <a
          href="https://www.google.com/u/1/recaptcha/admin/create"
          target="_blank"
          rel="noreferrer"
        >
          <u>Google recaptcha admin</u>
        </a>
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/recaptcha_step1.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/recaptcha_step1.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/recaptcha_step2.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/recaptcha_step2.png"
                alt="admin"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>

      <NavigationBtns />
    </div>
  );
};

export default FirebaseSetup;
