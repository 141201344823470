import FlagFillIcon from "remixicon-react/FlagFillIcon";
import { IoLanguageSharp } from "react-icons/io5";
import { GrCurrency } from "react-icons/gr";
import {
  BsChatLeftDots,
  BsMoonStars,
  BsPlayBtn,
  BsWallet2,
} from "react-icons/bs";
import {
  MdFavoriteBorder,
  MdOutlineAnalytics,
  MdOutlineBookmarkAdded,
  MdPayment,
} from "react-icons/md";
import { AiOutlineLogin } from "react-icons/ai";
import { GrTransaction } from "react-icons/gr";
import { RiFileList3Line, RiNotificationBadgeLine } from "react-icons/ri";
import { FaAnglesUp, FaMoneyBillTransfer } from "react-icons/fa6";
import { FaRegMap } from "react-icons/fa";
import { BiGitCompare } from "react-icons/bi";
import { FcMultipleDevices } from "react-icons/fc";
import DashboardFillIcon from "remixicon-react/DashboardFillIcon";
import LinksLineIcon from "remixicon-react/LinksLineIcon";
import { GiArtificialHive, GiPaper } from "react-icons/gi";
import { SiBrandfolder } from "react-icons/si";
import { TbCategory } from "react-icons/tb";
import React from "react";

export const quickFeatures = [
  {
    tag: "Multi Regions",
    title: "Multi Regions",
    subTitle: "Multi Regions, Countries,Cities",
    icon: <FlagFillIcon />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Language",
    title: "Multiple Language & RTL",
    subTitle: "Active RTL. You can easily change your preferred language",
    icon: <IoLanguageSharp />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Currency",
    title: "Multiple Currency",
    subTitle: "You can easily change your preferred currency",
    icon: <GrCurrency />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "THEMES",
    title: "DARK AND LIGHT THEMES",
    subTitle: "DARK AND LIGHT THEMES",
    icon: <BsMoonStars />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "600",
  },
  {
    tag: "PAYMENT",
    title: "MULTI PAYMENT",
    subTitle: "MULTI PAYMENT",
    icon: <MdPayment />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "500",
  },
  {
    tag: "CHAT",
    title: "ONLINE CHAT",
    subTitle: "ONLINE CHAT",
    icon: <BsChatLeftDots />,
    color: "#cce6ff",
    bgColor: "#76b5c5",
    delayAnimation: "300",
  },
  {
    tag: "LOGIN",
    title: "SOCIAL LOGIN",
    subTitle: "SOCIAL LOGIN",
    icon: <AiOutlineLogin />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "NOTIFICATION",
    title: "PUSH NOTIFICATION",
    subTitle: "PUSH NOTIFICATION",
    icon: <RiNotificationBadgeLine />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "100",
  },
  {
    tag: "WALLET",
    title: "WALLET ",
    subTitle: "WALLET",
    icon: <BsWallet2 />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "customer",
    title: "Customer app",
    subTitle: "Customer app",
    icon: <FcMultipleDevices />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Admin panel",
    title: "Admin panel",
    subTitle: "Admin panel",
    icon: <DashboardFillIcon />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Countries",
    title: "Countries",
    subTitle: "Create a regions countries cities by running seeder",
    icon: <FaRegMap />,
    color: "#fff7e6",
    bgColor: "#FAB758",
    delayAnimation: "300",
  },
  {
    tag: "Ads",
    title: "Ads",
    subTitle: "PRODUCT ADVERTISEMENTS",
    icon: <GiPaper />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "FAVORITE ADS",
    title: "FAVORITE ADS",
    subTitle: "FAVORITE ADS",
    icon: <MdFavoriteBorder />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "REPORTS",
    title: "REPORTS",
    subTitle: "REPORTS",
    icon: <RiFileList3Line />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Analytics",
    title: "Analytics",
    subTitle: "View the values and amounts of your orders and products live",
    icon: <MdOutlineAnalytics />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "100",
  },
  {
    tag: "Compare ads",
    title: "Compare ads",
    subTitle: "Compare ads",
    icon: <BiGitCompare />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "Referral",
    title: "Referral",
    subTitle: "Referral system",
    icon: <LinksLineIcon size={40} />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "BRAND",
    title: "BRAND",
    subTitle: "BRAND",
    icon: <SiBrandfolder />,
    color: "#cce6ff",
    bgColor: "#76b5c5",
    delayAnimation: "400",
  },
  {
    tag: "Payment history",
    title: "Payment history",
    subTitle: "Payment history",
    icon: <FaMoneyBillTransfer />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "Category hierarchy",
    title: "Category hierarchy",
    subTitle:
      "Organize items with clear multilevel categories for better navigation",
    icon: <TbCategory />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "AI integration",
    title: "AI integration ",
    subTitle:
      "Automatically generates item descriptions based on uploaded ad images for faster and more accurate listings",
    icon: <GiArtificialHive />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Ad promotion",
    title: "Ad promotion",
    subTitle:
      "Purchase ad promotion packages to increase visibility and attract more customers. Available in extended version",
    icon: <FaAnglesUp />,
    color: "#d6f5d6",
    bgColor: "#248f24",
    delayAnimation: "200",
  },
  {
    tag: "Video ads",
    title: "Video ads",
    subTitle: "Enhance your ads with video content.",
    icon: <BsPlayBtn size={40} />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Bookmark items",
    title: "Bookmark items",
    subTitle: "Save favorite listings for quick access later",
    icon: <MdOutlineBookmarkAdded />,
    color: "#cce6ff",
    bgColor: "#76b5c5",
    delayAnimation: "400",
  },
  {
    tag: "Send money to friends",
    title: "Send money to friends",
    subTitle: "Transfer funds to a friend’s wallet with a few clicks",
    icon: <GrTransaction />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
];
